const verbElement = document.getElementById("verb");
const locationElement = document.getElementById("location");
document.getElementById('btn-icon').style.display = 'none'

const form = document.getElementById("myForm");
form.addEventListener('submit', submitForm);

const verbs = ["connecting", "augmenting"];
const locations = ["Africa", "Senegal"];

let verbIndex = 0;
let locationIndex = 0;

function updateWords() {
  // Increment the indices and reset them to 0 when they reach the end of their respective arrays
  verbIndex = (verbIndex + 1) % verbs.length;
  locationIndex = (locationIndex + 1) % locations.length;

  verbElement.classList.add("slide-text");
  locationElement.classList.add("slide-text");

  setTimeout(() => {
    verbElement.innerHTML = `
          ${verbs[verbIndex]}
          <svg class="text-stroke" viewBox="0 0 154 13">
              <use href="#line"></use>
          </svg>
        `;
    locationElement.innerHTML = `
          ${locations[locationIndex]}
          <svg class="text-stroke" viewBox="0 0 154 13">
              <use href="#line"></use>
          </svg>
        `;

    verbElement.classList.remove("slide-text");
    locationElement.classList.remove("slide-text");
  }, 300);
}

function changeSpinnerVisibility(visible) {
  document.getElementById('btn-icon').style.display = visible ? 'block' : 'none'
}

changeSpinnerVisibility(false);
// const res=await fetch('https://kerasearch-wkfecdkt7q-uw.a.run.app/waitlist/api/waitlist',

async function submitForm(event) {
  event.preventDefault();
  changeSpinnerVisibility(true)
  const data = new FormData(form)
  const email = data.get('email')
  if (!email) return
  try {
    const res = await fetch('https://search.kerahealth.one/waitlist/api/waitlist', {
      method: 'POST',
      body: JSON.stringify({ email: email }),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    });
    const data = JSON.parse(await res.text())
    Toastify({
      text: data.message,
      duration: 3000,
      gravity: "top", // `top` or `bottom`
      position: "center", // `left`, `center` or `right`
      stopOnFocus: true, // Prevents dismissing of toast on hover
      style: {
        background: "#049559",
        borderRadius: "8px",
      },
    }).showToast();
  }
  catch (error) {
    alert('Failed to signup for waitlist, please try again')
  }
  form.reset()
  changeSpinnerVisibility(false)
}

setInterval(updateWords, 7000); // 5000 milliseconds (5 seconds)

window.onload = function () {
  if (!getCookie("cookieConsent")) {
    document.getElementById("cookieConsentContainer").style.display = "block";
  }
}

window.acceptCookieConsent = function () {
  setCookie("cookieConsent", "1", 365);
  document.getElementById("cookieConsentContainer").style.display = "none";
}

function setCookie(name, value, days) {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

function updateYear() {
  const date2 = new Date();
  const year2 = date2.getFullYear();
  document.getElementById('year').innerHTML = `Kera &copy; ${year2}, All rights reserved.`;
}

document.addEventListener('DOMContentLoaded', updateYear);

(function (w, d, s, o, f, js, fjs) {
  w[o] = w[o] || function () { (w[o].q = w[o].q || []).push(arguments) };
  js = d.createElement(s), fjs = d.getElementsByTagName(s)[0];
  js.id = o; js.src = f; js.async = 1; fjs.parentNode.insertBefore(js, fjs);
}(window, document, 'script', 'gdprBadge', 'https://cdn.gdprlocal.com/static/widget.js'));


gdprBadge('init', { element: document.getElementById('gdprVerifier'), verificationId: '68XFQAB' });

(function (c, l, a, r, i, t, y) {
  c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments) };
  t = l.createElement(r); t.async = 1; t.src = "https://www.clarity.ms/tag/" + i;
  y = l.getElementsByTagName(r)[0]; y.parentNode.insertBefore(t, y);
})(window, document, "clarity", "script", "o8b7s1ei9w");